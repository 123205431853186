import React, { useRef, useEffect } from "react";
import ButtonPrimary from "./misc/ButtonPrimary";
import Lottie from 'lottie-react';
import animation from '../images/lottie-hero.json';
import { Trans } from 'gatsby-plugin-react-i18next';
import EmailButton from "./misc/EmailButton";

const Hero = ({
  listUser = [
    {
      name: "Something gotta give"
    },
    {
      name: "Something gotta give"
    },
    {
      name: "Something gotta give"
    },
  ],
}) => {
  return (
    <div className="max-w-screen-xl mt-24 px-8 xl:px-16 mx-auto" id="about">
      <div className="grid grid-flow-row sm:grid-flow-col grid-rows-2 md:grid-rows-1 sm:grid-cols-2 gap-8 py-6 sm:py-16 ">
        <div className="flex w-full">
          <div className="w-full h-full">
            <Lottie animationData={animation} />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center md:items-start row-start-2 sm:row-start-1">
          <h1 className="text-3xl lg:text-4xl xl:text-5xl font-medium text-center md:text-left text-black-600 leading-normal">
            👋 <Trans>WELCOME_MESSAGE</Trans>
          </h1>
          <p className="text-black-500 mt-4 mb-6 text-center md:text-left">
            <Trans>INTRO</Trans>
          </p>
          <div className="flex w-full justify-center md:ml-1">
            <EmailButton emailAddress={'nabih.1992@live.com'}><Trans>GET_STARTED</Trans></EmailButton>
          </div>
        </div>
      </div>

      {/*<div className="relative w-full flex">
        <div className="rounded-lg w-full grid grid-flow-row sm:grid-flow-row grid-cols-1 sm:grid-cols-3 py-9 divide-y-2 sm:divide-y-0 sm:divide-x-2 divide-gray-100 bg-white-500 z-10">
          {listUser.map((listUsers, index) => (
            <div
              className="flex items-center justify-start sm:justify-center py-4 sm:py-6 w-8/12 px-4 sm:w-auto mx-auto sm:mx-0"
              key={index}
            >
              <div className="flex mx-auto w-40 sm:w-auto">
                <div className="flex flex-col">
                  <p className="text-lg text-black-500">{listUsers.name}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div
          className="absolute bg-black-600 opacity-5 w-11/12 roudned-lg h-64 sm:h-48 top-0 mt-8 mx-auto left-0 right-0"
          style={{ filter: "blur(114px)" }}
        ></div>
          </div>*/}
    </div>
  );
};

export default Hero;
