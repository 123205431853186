import React from "react";
import SimpleLogo from '../../images/Logo-simple.svg';
import { Trans } from 'gatsby-plugin-react-i18next';

const Footer = () => {
  return (
    <div className="bg-white-300 pt-16 pb-16">
      <div className="max-w-screen-xl w-full mx-auto px-6 sm:px-8 lg:px-16">
        <div className="flex flex-col lg:flex-row items-center">
          <img src={SimpleLogo} className="h-16 w-auto mb-6" alt="simple-logo" />
          <p className="flex-1 mb-4 px-4">
            <Trans>FOOTER_PARAGRAPH</Trans>
          </p>
          <div className="flex mt-2 mb-8 -mx-2">
            <div className="mx-2 bg-white-500 rounded-full items-center justify-center flex p-2 shadow-md">
              {/* LinkedIn */}
              <a href="https://de.linkedin.com/in/nabih92" target="_blank" className="text-orange-500">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-linkedin" width="36" height="36" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <rect x="4" y="4" width="16" height="16" rx="2" />
                  <line x1="8" y1="11" x2="8" y2="16" />
                  <line x1="8" y1="8" x2="8" y2="8.01" />
                  <line x1="12" y1="16" x2="12" y2="11" />
                  <path d="M16 16v-3a2 2 0 0 0 -4 0" />
                </svg>
              </a>
            </div>
            <div className="mx-2 bg-white-500 rounded-full items-center justify-center flex p-2 px-3 shadow-md">
              {/* Xing */}
              <a href="https://www.xing.com/profile/Nabih_HajIsmail/cv" target="_blank" className="text-orange-500">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-xing" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M16 21l-4 -7l6.5 -11"></path>
                  <path d="M7 7l2 3.5l-3 4.5"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <p className="text-gray-400 text-center">©2022 nabih.io</p>
      </div>
    </div>
  );
};

export default Footer;
