import * as React from "react"
import { Helmet } from "react-helmet";
import CV from "../components/CV";
import Projects from "../components/Projects";
import Hero from "../components/Hero";
import Layout from "../components/Layout/Layout";
import { Trans } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';

export default function Home({ data }) {
  console.log(data);
  let projects = data.projects.edges.map(project => project.node);

  return (
    <>
      <Helmet>
        <link rel="icon" href="/favicon.ico" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,500;0,600;0,700;1,300&display=swap"
          rel="stylesheet"
        />
        <title>Nabih Haj Ismail - System Administration Expert</title>
        <meta
          name="description"
          content="Nabih Haj Ismail - System Administration Expert"
        />
      </Helmet>
      <Layout>
        <Hero />
        <CV />
        <Projects projects={projects} />
      </Layout>
    </>
  );
};

export const query = graphql`
  query($language: String!) {
    locales: allNotionLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    projects: allProjects {
      edges {
        node {
          id
          title
          description
        }
      }
    }
  }
`;
