import React from "react";

const TimelineEntry = ({ company, duration, isCurrent, jobTitle, jobDescription, location, time }) => {

  function renderJobDescription(jobDescription) {
    if (!Array.isArray(jobDescription)) {
      return jobDescription;
    } else {
      return (
        <ul class="list-disk">
          {jobDescription.map(description =>
            <li>{description}</li>)}
        </ul>
      );
    }
  };

  return (
      <>
      <li class="mb-10 md:ml-6 md:grid md:grid-cols-4">
        <div class="hidden md:block">
          <p class="mb-2 text-sm text-right mt-1 mr-10 font-normal leading-none text-gray-400">
            {time}
          </p>
          <p class="mb-2 text-sm text-right mr-10 font-normal leading-none text-gray-400">
            {!isCurrent && duration}
          </p>
        </div>
        <div class="col-span-3 relative pl-6 border-l border-gray-200">
          <span class="flex absolute -left-3 justify-center items-center w-6 h-6 bg-orange-500 rounded-full ring-8 ring-white-500">
            <svg class="w-3 h-3 text-white-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"></path></svg>
          </span>
          <h3 class="flex items-center mb-1 text-lg text-left font-semibold text-gray-900">
            {jobTitle}
            {isCurrent && <span class="bg-orange-500 text-white-500 text-sm font-medium mr-2 px-2.5 py-0.5 rounded ml-3">Current</span>}
          </h3>
          <p class="block mb-2 text-sm text-left font-normal leading-none text-gray-400">
            {company}
          </p>
          <p class="block mb-2 text-sm text-left font-normal leading-none text-gray-400">
            {location}
          </p>
          <time class="block mb-2 text-sm text-left font-normal leading-none text-gray-400">
            {time} {duration}
          </time>
          <p class="mb-4 text-base text-left font-normal text-gray-600">
            {renderJobDescription(jobDescription)}
          </p>
        </div>
      </li>
    </>
  );
};

export default TimelineEntry;
