import React from "react";
import { Trans } from 'gatsby-plugin-react-i18next';
import TimelineEntry from "./misc/TimelineEntry";

const CV = () => {
  return (
    <div
      className="max-w-screen-xl mt-8 mb-6 sm:mt-14 sm:mb-14 px-6 sm:px-8 lg:px-16 mx-auto"
      id="cv"
    >
      <div className="max-w-screen-xl px-6 sm:px-8 lg:px-16 mx-auto flex flex-col w-full text-center justify-center">
        <div className="flex flex-col w-full">
          <h3 className="text-2xl sm:text-3xl lg:text-4xl font-medium text-black-600 leading-relaxed">
            Curriculum Vitae
          </h3>
          <p className="leading-normal w-full md:w-9/12 mx-auto my-2 text-center">
            <Trans>CV_INTRO</Trans>
          </p>
          <div class="block mx-auto my-8">
            <a href="https://www.dropbox.com/s/fgsxuxxdjcefmlt/Resume.pdf?dl=1" class="flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-orange-500 focus:z-10 focus:ring-2 focus:ring-orange-500 focus:text-orange-500">
              <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z" clip-rule="evenodd"></path></svg>
              <Trans>
                  BUTTON_DOWNLOAD_CV
              </Trans>
            </a>
          </div>
          <div class="w-9/12 md:grid md:grid-cols-4 mt-2 mb-10 mx-auto">
            <h3 class="text-xl text-center md:text-left md:col-span-2"><Trans>WORK_EXPERIENCE</Trans></h3>
          </div>
          <ol class="w-full md:w-9/12 mx-auto relative">
            <TimelineEntry
              company="Grassau GmbH"
              jobTitle="Azubi - Fachinformatiker für Systemintegration"
              jobDescription={[
                "- Betreuen von Kundenanfragen",
                "- Systeminstallation (Software Development) und Störungsbeseitigung",
                "- Unterstützung bei Durchführung von Projekten",
                "- Erstellen von Dokumentationsbeiträgen in Wiki und Ticketsystem (Jira)",
                "- Administration von Exchange und Exchange online",
                "- Administration von Microsoft Produkten (Azure & Office 365)",
                "- 3CX Cloudtelefonie (Appliances, Cloud Edition, UCC Clients)",
                "- VMware, ESXI, Microsoft HyperV"
              ]}
              isCurrent
              time="Apr. 2021 - Heute"
              duration="11 Monate"
              location="Wedel, Schleswig-Holstein, Germany"
            ></TimelineEntry>
            <TimelineEntry
              company="Start with a Friend e.V."
              jobTitle="Fellow"
              jobDescription={[
                "- Projektvertreter in Hamburg",
                "- Vermittlung zwischen Locals und den Einwander*innen",
                "- Betreuung der Tandems",
                "- Betreuung der Ehrenamtlichen im Standort Hamburg",
                "- Regelmäßige Reports an der Bundesteam"
              ]}
              time="Jan. 2020 - Nov. 2021"
              duration="1 Jahr 11 Monate"
              location="Hamburg, Deutschland"
            ></TimelineEntry>
            <TimelineEntry
              company="Vossloh Rail Services GmbH"
              jobTitle="Praktikant in der Einkaufsabteilung"
              jobDescription={[
                "- Enge Zusammenarbeit mit den strategischen Einkäufer",
                "- Bedarfe über Banfe, E-Kataloge und Rahmenverträge bearbeiten",
                "- Erstellen von Anfragen",
                "- Kontrolle von Auftragsbestätigung",
                "- Überwachung der Liefertermine",
                "- Mitarbeit bei der Analyse von Einkaufskennzahlen und Abteilung von Maßnahme"
              ]}
              time="Feb. 2020 - März 2021"
              duration="1 Jahr 2 Monate"
              location="Hamburg, Deutschland"
            ></TimelineEntry>
            <TimelineEntry
              company="Schülke & Mayr"
              jobTitle="Produktionsmitarbeiter"
              time="Okt. 2018 - Jan. 2019"
              duration="4 Monate"
              location="Hamburg, Deutschland"
            ></TimelineEntry>
            <TimelineEntry
              company="Sultan Balik Restaurant"
              jobTitle="Grafik-Designer und Marketeer"
              jobDescription={[
                "- Illustrationen und Bilder für Social Media",
                "- Socail Media - Marketing-Kampagnen führen"
              ]}
              time="Okt. 2016 - März 2017"
              duration="6 Monate"
              location="Istanbul, Turkey"
            ></TimelineEntry>
          </ol>

          <div class="w-9/12 md:grid md:grid-cols-4 mt-2 mb-10 mx-auto">
            <h3 class="text-xl text-center md:text-right"><Trans>EDUCATION</Trans></h3>
          </div>
          <ol class="w-full md:w-9/12 mx-auto relative">
            <TimelineEntry
              company="Damascus University"
              jobTitle="Bachelor's degree - Betriebswirtschaftslehre "
              jobDescription="Anerkannt durch die Zentralstelle für ausländische Bildungswesen (Entspricht einem deutschen Hochschulabschluss auf Bachelor-Ebene)"
              time="2011 - 2016"
              location=""
            ></TimelineEntry>
          </ol>

          <div class="w-9/12 md:grid md:grid-cols-4 mt-2 mb-10 mx-auto">
            <h3 class="text-xl text-center md:text-right"><Trans>VOLUNTARY_WORK</Trans></h3>
          </div>
          <ol class="w-full md:w-9/12 mx-auto relative">
            <TimelineEntry
              company="Start with a Friend e.V."
              jobTitle="Public Relations"
              jobDescription=""
              time="Sept 2019 - Heute"
            ></TimelineEntry>
            <TimelineEntry
              company="Red Blood Cells University Club"
              jobTitle="Graphic Designer - Financial Manager"
              jobDescription=""
              time="März 2013 - Aug 2015"
              duration="2 Jahre 6 Monate"
            ></TimelineEntry>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default CV;