import React from "react";

const EmailButton = ({ children, emailAddress }) => {
  return (
    <a
      href={`mailto:${emailAddress}`}
      className="py-3 lg:py-4 px-12 lg:px-16 text-white-500 font-semibold rounded-lg bg-orange-500 hover:shadow-orange-md transition-all outline-none">
      <span className="px-4">
        {children}
      </span>
    </a>
  );
};

export default EmailButton;
